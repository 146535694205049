import { Injectable } from '@angular/core';
import { Auth } from '../models/auth';
import { Observable, map, of, switchMap } from 'rxjs';
import { BaseService } from './base/base.service';
import { LocalStorageService } from './base/localStorage.service';
import { ModelBaseService } from './base/modelBase.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService  extends ModelBaseService<Auth>  {

  /**
   *
   */
  constructor(public baseService:BaseService,private localStorageService:LocalStorageService) {
    super(`auth`,baseService,"default")
  }

  // Simule la connexion de l'utilisateur
  login(auth: Auth): Observable<any> {
    this.checkBaseUrl();
    return this.baseService.post('auth/login', auth).pipe(
      map((response) => {
        this.localStorageService.saveToken(response.token);
        this.localStorageService.saveUser(response.user);
        // Vous pouvez retourner quelque chose ici si nécessaire
        return response; 
      })
    );
  }


  // Simule la déconnexion de l'utilisateur
  logout(): void {
    this.localStorageService.destroyToken();
  }

  // Vérifie si l'utilisateur est authentifié
  isLoggedIn(): boolean {
    return this.localStorageService.isAuthenticated();
  }
}
