import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Tableau de bord",
      icon: "home",
      type: "link",
      badgeType: "light-primary",
      active: false,
      path: "/admin-dashboard",
  },
  {
      headTitle1: "Achat des véhicules",
      headTitle2: "Gestion des commendes véhicules",
  },
  {
      title: "Gestion des commandes",
      icon: "ecommerce",
      type: "sub",
      active: false,
      children: [
            {
              title: "Créer une commande",
              icon: "stroke-icon",
              type: "link",
              active: false,
              path: "/buy/order/create-order",
          },
          {
              title: "Liste des commandes ",
              icon: "stroke-icon",
              type: "link",
              active: false,
              path: "/buy/order/list-orders",
          },
          {
            title: "Liste des paiements",
            icon: "stroke-icon",
            type: "link",
            active: false,
            path: "/buy/order/list-paiements",
        },
        
      ],
  },
  {
    title: "Gestion des véhicules",
    icon: "layout",
    type: "sub",
    active: false,
    children: [
          {
            title: "Ajouter un  véhicule",
            icon: "stroke-icon",
            type: "link",
            active: false,
            path: "/buy/vehicule/create-vehicule",
        },
        {
            title: "Liste des véhicules",
            icon: "stroke-icon",
            type: "link",
            active: false,
            path: "/buy/vehicule/list-vehicules",
        },
       
      
    ],
},
{
  headTitle1: "Réservation des véhicules",
  headTitle2: "Gestion des réservations véhicules",
},
{
  title: "Gestion des réservations",
  icon: "bonus-kit",
  type: "sub",
  active: false,
  children: [
        {
          title: "Créer une réservation",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "/rent/order/create-reservation",
      },
      {
          title: "Liste des réservations ",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "rent/order/list-reservations",
      },
      {
        title: "Calendrier des reservations ",
        icon: "stroke-icon",
        type: "link",
        active: false,
        path: "rent/order/schedule-reservations",
    },
      {
        title: "Liste des paiements ",
        icon: "stroke-icon",
        type: "link",
        active: false,
        path: "/rent/order/list-paiements",
    },
    
  ],
},
{
title: "Gestion des véhicules",
icon: "layout",
type: "sub",
active: false,
children: [
      {
        title: "Ajouter un  véhicule",
        icon: "stroke-icon",
        type: "link",
        active: false,
        path: "/rent/vehicule/create-vehicule",
    },
    {
      title: "Liste des véhicules",
      icon: "stroke-icon",
      type: "link",
      active: false,
      path: "/rent/vehicule/list-vehicules",
  },
   
  
],

},
{
  title: "Gestion des tarifications",
  icon: "layout",
  type: "sub",
  active: false,
  children: [
        {
          title: "Ajouter un  tarification",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "/rent/tarification/create-tarification",
      },
      {
        title: "Liste des tarifications",
        icon: "stroke-icon",
        type: "link",
        active: false,
        path: "/rent/tarification/list-tarifications",
    },
     
    
  ],
  
  },
  {
    title: "Gestion des stocks",
    icon: "layout",
    type: "sub",
    active: false,
    children: [
          {
            title: "Ajouter un  stock",
            icon: "stroke-icon",
            type: "link",
            active: false,
            path: "/rent/stock/create-stock",
        },
        {
          title: "Liste des stocks",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "/rent/stock/list-stocks",
      },
       
      
    ],
    
    },
    {
      headTitle1: "Gestion des flottes",
      headTitle2: "Gestion des flottes de véhicules",
    },
    {
      title: "Gestion des demandes",
      icon: "bonus-kit",
      type: "sub",
      active: false,
      children: [
            {
              title: "Créer une demande",
              icon: "stroke-icon",
              type: "link",
              active: false,
              path: "/torent/order/create-demande",
          },
          {
              title: "Liste des demandes",
              icon: "stroke-icon",
              type: "link",
              active: false,
              path: "torent/order/list-demandes",
          },
          {
            title: "Liste des paiements ",
            icon: "stroke-icon",
            type: "link",
            active: false,
            path: "/torent/order/list-paiements",
        },
        
      ],
    },
    
    {
      headTitle1: "Gestion des ventes",
      headTitle2: "Gestion des ventes de véhicules",
    },
    {
      title: "Gestion des demandes",
      icon: "bonus-kit",
      type: "sub",
      active: false,
      children: [
            {
              title: "Créer une demande",
              icon: "stroke-icon",
              type: "link",
              active: false,
              path: "/sell/order/create-demande",
          },
          {
              title: "Liste des demandes",
              icon: "stroke-icon",
              type: "link",
              active: false,
              path: "sell/order/list-demandes",
          },
          {
            title: "Liste des paiements ",
            icon: "stroke-icon",
            type: "link",
            active: false,
            path: "/sell/order/list-paiements",
        },
        
      ],
    },

  
  {
      headTitle1: "Blog",
      headTitle2: "Gestion des articles de blog",
  },
  {
      title: "Gestion des articles",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
          {
              title: "Créer un article",
              icon: "stroke-icon",
              type: "link",
              active: false,
              path: "blog/article/create-article",
          },
          {
              title: "Liste des articles",
              icon: "stroke-icon",
              type: "link",
              active: false,
              path: "blog/article/list-articles",
          },
         
         
      ],
  },
  {
    title: "Gestion des catégories ",
    icon: "task",
    type: "sub",
    active: false,
    children: [
        
        {
            title: "Liste des catégories",
            icon: "stroke-icon",
            type: "link",
            active: false,
            path: "blog/category/list-categories",
        },
       
       
    ],
},
{
  title: "Gestion des tags",
  icon: "form",
  type: "sub",
  active: false,
  children: [
      
      {
          title: "Liste des tags",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/tag/list-tags",
      },
     
     
  ],
},
  
  
  {
      headTitle1: "Clients",
      headTitle2: "Gestion des comptes clients",
  },
  {
      title: "Gestion des clients",
      type: "sub",
      icon: "user",
      badgeType: "light-primary",
      active: false,
      children: [
           { path: "/customer/list-customers", title: "Liste des clients", type: "link" },
           { path: "/customer/list-kycs", title: "Liste des KYC", type: "link" },

          
      ],
  },
 
  
  {
      headTitle1: "Galerie image",
  },
  {
      title: "Gestion matricule véhicule",
      type: "sub",
      icon: "gallery",
      badgeType: "light-primary",
      active: false,
      children: [
          // { path: "/galery/create-matricule", title: "Ajouter un matricule", type: "link" },
          { path: "/galery/list-matricules", title: "Liste des matricules", type: "link" },
      ],
  },
    
  {
    headTitle1: "CRM",
},
{
    title: "Gestion clientele",
    type: "sub",
    icon: "gallery",
    badgeType: "light-primary",
    active: false,
    children: [
        // { path: "/galery/create-matricule", title: "Ajouter un matricule", type: "link" },
        { path: "/crm/list-contacts", title: "Liste des contacts", type: "link" },
    ],
},
  {
      headTitle1: "Paramétrage de véhicules",
      headTitle2: "Gestion des parametres de véhicule",
  },
  {
      title: "Paramètre véhicule",
      type: "sub",
      icon: "layout",
      badgeType: "light-primary",
      active: false,
      children: [
          
          { path: "/setting-vehicule/marque/list-marques", title: "Marques et modèles", type: "link" },
          { path: "/setting-vehicule/propriete/list-proprietes", title: "Propriétes", type: "link" },
          { path: "/setting-vehicule/list-users", title: "Etats de véhicule", type: "link" },

      ],
  },
  {
    headTitle1: "Administrateurs",
    headTitle2: "Gestion des comptes administrateurs",
},
{
    title: "Administrateurs",
    type: "sub",
    icon: "user",
    badgeType: "light-primary",
    active: false,
    children: [
        
        { path: "/user-admin/roles", title: "Ajouter un administrateur", type: "link" },
        { path: "/user-admin/list-users", title: "Liste des administrateurs", type: "link" },
        { path: "/user-admin/list-users", title: "Proprietés de véhicule", type: "link" },
        { path: "/user-admin/list-users", title: "Etats de véhicule", type: "link" },

    ],
},
{
  title: "Roles",
  type: "sub",
  icon: "social",
  badgeType: "light-primary",
  active: false,
  children: [
      { path: "/user-admin/list-users", title: "Liste des rôles", type: "link" }
  ],
},
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
