import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from './localStorage.service';


@Injectable({
    providedIn: 'root'
})
export class BaseService {
    public baseUrl = ""; // Remplacez variables.apiUrl par l'URL de votre API

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,

    ) {

     }

    private getHeaders(): HttpHeaders {
        const token = this.localStorageService.getToken();
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        });
    }

    private getMultipartHeaders(): HttpHeaders {
        const token = this.localStorageService.getToken();
        return new HttpHeaders({
            Authorization: `Bearer ${token}`
        });
    }

    getDownload(endPoint: string, filterString?: string): Observable<Blob> {
        const headers = this.getHeaders();
        const url = filterString ? `${this.baseUrl}/${endPoint}${filterString}` : `${this.baseUrl}/${endPoint}`;
        return this.http.get(url, { headers, responseType: 'blob' }).pipe(catchError(this.handleError));
    }

    getOne(endPoint: string, id: number): Observable<any> {
        const url = `${this.baseUrl}/${endPoint}/${id}`;
        const headers = this.getHeaders();

        return this.http.get(url, { headers }).pipe(catchError(this.handleError));
    }

    getAll(endPoint: string, filterString?: string): Observable<any> {
        const headers = this.getHeaders();

        const url=filterString?`${this.baseUrl}/${endPoint}${filterString}`:`${this.baseUrl}/${endPoint}`;

        return this.http.get(url, { headers }).pipe(catchError(this.handleError));
    }

    post(endPoint: string, item: any): Observable<any> {
        const headers = this.getHeaders();

        return this.http.post(`${this.baseUrl}/${endPoint}`, item, { headers }).pipe(catchError(this.handleError));
    }

    put(endPoint: string, id: any, item: any): Observable<any> {
        const url = `${this.baseUrl}/${endPoint}/${id}`;
        const headers = this.getHeaders();

        return this.http.put(url, item, { headers }).pipe(catchError(this.handleError));
    }

    putWithoutId(endPoint: string, item: any): Observable<any> {
        const url = `${this.baseUrl}/${endPoint}`;
        const headers = this.getHeaders();

        return this.http.put(url, item, { headers }).pipe(catchError(this.handleError));
    }

    delete(endPoint: string, id: number): Observable<void> {
        const url = `${this.baseUrl}/${endPoint}/${id}`;
        const headers = this.getHeaders();

        return this.http.delete<void>(url, { headers }).pipe(catchError(this.handleError));
    }


    postMultipart(endPoint: string, formData: FormData): Observable<any> {
        const headers = this.getMultipartHeaders();
        const url = `${this.baseUrl}/${endPoint}`;

        return this.http.post(url, formData, { headers }).pipe(catchError(this.handleError));
    }

    putMultipart(endPoint: string, id: number, formData: FormData): Observable<any> {
        const url = `${this.baseUrl}/${endPoint}/${id}`;
        const headers = this.getMultipartHeaders();

        return this.http.put(url, formData, { headers }).pipe(catchError(this.handleError));
    }

    postWithoutHeader(endPoint: string, item: any): Observable<any> {

        return this.http.post(`${this.baseUrl}/${endPoint}`, item).pipe(catchError(this.handleError));
    }

    getAllPaginated(endPoint: string, params: any = {}): Observable<any> {
        const headers = this.getHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        const requestOptions = {
            headers,
            params: httpParams
        };

        return this.http.get(`${this.baseUrl}/${endPoint}`, requestOptions);
    }

    postFormDataGalerie(endpoint: string, data: FormData): Observable<any> {

        const headers = new HttpHeaders({
            'Authorization': 'Basic ' + 'Y2Vyb3VhbWJhQGdtYWlsLmNvbTpHb29nbGUwMTA0MTk5NQ=='
        });

        const requestOptions = {
            headers,
        };

        return this.http.post(`${this.baseUrl}/${endpoint}`,data, requestOptions).pipe(catchError(this.handleError));

         
      }

    
    
    
    deleteFormDataGalerie(endpoint: string): Observable<any> {

        const headers = new HttpHeaders({
            'Authorization': 'Basic ' + 'Y2Vyb3VhbWJhQGdtYWlsLmNvbTpHb29nbGUwMTA0MTk5NQ=='
        });

        const requestOptions = {
            headers,
        };
        return this.http.delete(`${this.baseUrl}/${endpoint}`, requestOptions);
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage = 'Une erreur s\'est produite.';

        if (error.error instanceof ErrorEvent) {
            // Erreur du côté client
            errorMessage = `Erreur : ${error.error.message}`;
        } else {
            // Erreur du côté serveur
            if (error.status === 400) {
                errorMessage = `${error.error.message}`;
            }
            else if (error.status === 401) {
                errorMessage = `${error.error.message}`;
            } else if (error.status === 404) {
                errorMessage = `${error.error.message}`;
            } else if (error.status === 500) {
                errorMessage = `${error.error.message}`;
            } else {
                // Autres codes d'erreur
                errorMessage = 'Erreur inattendue. Veuillez réessayer plus tard.';
            }
        }

        return throwError(errorMessage);
    }
}
