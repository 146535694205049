// model-service.service.ts
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ModelBaseService<T> {
  constructor(
    public resourceName: string,
    public baseService: BaseService,
    public urlBase:string
  ) {
   
  }
  getAll(filterString?: string) {
    this.checkBaseUrl();
    return this.baseService.getAll(`${this.resourceName}`, filterString);
  }

  getOne(id: any) {
    this.checkBaseUrl();
    return this.baseService.getOne(`${this.resourceName}`, id);
  }


  create(item: T | any) {
    this.checkBaseUrl();
    return this.baseService.post(`${this.resourceName}`, item);
  }

  update(id: any, item: T | any) {
    this.checkBaseUrl();
    return this.baseService.put(`${this.resourceName}`, id, item);
  }

  createFormData(item: any) {
    this.checkBaseUrl();
    return this.baseService.postMultipart(`${this.resourceName}`, item);
  }

  updateFormData(id: any, item: any) {
    this.checkBaseUrl();
    return this.baseService.putMultipart(`${this.resourceName}`, id, item);
  }

  delete(id: any) {
    this.checkBaseUrl();
    return this.baseService.delete(`${this.resourceName}`, id);
  }

  getAllPaginated(endPoint: string, params: any = {}) {
    this.checkBaseUrl();
    const url = endPoint ? `${this.resourceName}/${endPoint}` : this.resourceName;
    return this.baseService.getAllPaginated(url, params);
  }

  checkBaseUrl(){

    if(this.urlBase=='default'){
      this.baseService.baseUrl=environment.apiUrl;
    }else{
      this.baseService.baseUrl=this.urlBase;
    }
    console.log("BaseUrllll"+this.baseService.baseUrl);

  }
}
